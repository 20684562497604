import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Ensure you have FontAwesome imported
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Ensure you import the LinkedIn icon

const Footer = () => {
    return (
        <footer className="p-4 shadow md:flex md:items-center md:justify-between md:p-6 mt-auto">
            <div>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Getbook™. All Rights Reserved.</span>
                <br/>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">Made with ❤️ by </span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/parampatel21/" className="text-sm text-gray-500 sm:text-center dark:text-gray-400 hover:underline">
                    <FontAwesomeIcon className="ml-0.5" icon={faLinkedin} /> Param Patel
                </a>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">, </span>
                <a target="_blank" rel="noopener noreferrer" href="https://reldamedia.com" className="text-sm text-gray-500 sm:text-center dark:text-gray-400 hover:underline">
                    Richard Adler
                </a>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">, and other minor contributors.</span>
            </div>
            <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
                <li>
                    <Link to="/privacy-policy" target='_blank' className="mr-4 hover:underline md:mr-6">Privacy Policy</Link>
                </li>
                <li>
                    <Link to="/terms-of-service" target='_blank' className="mr-4 hover:underline md:mr-6">Terms of Service</Link>
                </li>
                <li>
                <a target="_blank" rel="noopener noreferrer" href="https://forms.gle/fM9ZhMgnQGQHepaWA" className="text-sm text-gray-500 sm:text-center dark:text-gray-400 hover:underline">
                    Contact Us
                </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
