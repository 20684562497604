import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../supabase';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);

  const fetchSubscriptionStatus = async () => {
    const { data, error } = await supabase.rpc('get_active_subscription_plan');
    setSubscription(data);
    // console.log(data);
    if (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

  const registerStripeCustomer = async () => {
    const { data, error } = await supabase.functions.invoke('create-stripe-customer', {
      body: JSON.stringify({})
    });
    // console.log(data);
    // console.log(error);
  }

  useEffect(() => {
    const fetchData = async () => {
      const checkCustomerId = async () => {
        const { data: customers, error } = await supabase
          .from('customers')
          .select('id')
          .eq('id', (await supabase.auth.getUser()).data.user.id);

        if (error) {
          console.error('Error fetching customer:', error);
          return;
        }

        if (customers.length === 0) {
          await registerStripeCustomer();
        }
      };

      await checkCustomerId();
      await fetchSubscriptionStatus();
    };

    fetchData();
  }, []); // Add `subscription` as a dependency

  return (
    <SubscriptionContext.Provider value={{ subscription, setSubscription, fetchSubscriptionStatus }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
