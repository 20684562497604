import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabase';

export default function ChangePassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // New state for alert type
    const [readyToUpdate, setReadyToUpdate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const recoveryListener = supabase.auth.onAuthStateChange(async (event, session) => {
            if (event === "PASSWORD_RECOVERY") {
                if (session) {
                    setReadyToUpdate(true); // Enable the form to update password
                } else {
                    setMessage('Failed to verify recovery session.');
                    setAlertType('error'); // Set alert type to error
                }
            }
        });

        return () => {
            recoveryListener.data.subscription.unsubscribe();
        };
    }, [navigate]);

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        if (!password || !confirmPassword) {
            setMessage('Please fill in all fields.');
            setAlertType('error');
            return;
        }
        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            setAlertType('error');
            return;
        }
        try {
            const { error } = await supabase.auth.updateUser({
                password: password
            });
            if (error) throw error;
            setMessage('Password updated successfully! Redirecting...');
            setAlertType('success'); // Set alert type to success
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } catch (error) {
            setMessage('Failed to update password: ' + error.message);
            setAlertType('error'); // Set alert type to error
        }
    };

    return (
        <div className="hero min-h-screen bg-base-200">
            <div className="hero-content flex flex-col">
                <div className="text-center">
                    <h1 className="text-5xl font-bold">Change Your Password</h1>
                </div>
                <div className="card w-full max-w-md shadow-2xl bg-base-100">
                    {readyToUpdate ? (
                        <form className="card-body" onSubmit={handleUpdatePassword}>
                            {message && (
                                <div className={`alert alert-${alertType}`}>
                                    {alertType === 'success' ? (
                                        <div className="flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                            <span>{message}</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                            <span>{message}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">New Password</span>
                                </label>
                                <input
                                    type="password"
                                    placeholder="Enter new password"
                                    className="input input-bordered"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Confirm Password</span>
                                </label>
                                <input
                                    type="password"
                                    placeholder="Confirm new password"
                                    className="input input-bordered"
                                    required
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-control mt-6">
                                <button type="submit" className="btn btn-primary">Update Password</button>
                            </div>
                        </form>
                    ) : (
                        <div className="card-body flex justify-center items-center text-center h-full">
                            <p>Loading or invalid link...</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
