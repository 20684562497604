import React from 'react';
import { Link } from 'react-router-dom';

const page404 = () => {
  return (
    <body className="bg-base-200">
      <div className="hero min-h-screen">
        <div className="hero-content text-center">
          <div className="max-w-md mx-auto">
            <h1 className="text-8xl font-bold text-red-500 mb-4">404</h1>
            <p className="text-lg text-white-600">Oops! Looks like the page you're looking for doesn't exist. Create an account on <Link to="/" className="text-blue-500 hover:underline">Getbook</Link> to get your own custom book link!</p>
          </div>
        </div>
      </div>
    </body>
  );
}

export default page404;
