import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mt-8 mb-4">Terms of Service</h1>
            <embed src="/TERMS OF SERVICE Getbook.pdf" type="application/pdf" width="100%" height="600px" />
        </div>
    );
};

export default TermsOfService;