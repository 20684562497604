import React, { useEffect, useState } from 'react';
import NotFoundPage from '../pages/404';
import supabase from '../supabase';

const countries =  {
  "us": "https://www.amazon.com",
  "uk": "https://www.amazon.co.uk",
  "ca": "https://www.amazon.ca",
  "de": "https://www.amazon.de",
  "es": "https://www.amazon.es",
  "fr": "https://www.amazon.fr",
  "it": "https://www.amazon.it",
  "jp": "https://www.amazon.co.jp",
  "in": "https://www.amazon.in",
  "cn": "https://www.amazon.cn",
  "sg": "https://www.amazon.com.sg",
  "mx": "https://www.amazon.com.mx",
  "ae": "https://www.amazon.ae",
  "br": "https://www.amazon.com.br",
  "nl": "https://www.amazon.nl",
  "au": "https://www.amazon.com.au",
  "tr": "https://www.amazon.com.tr",
  "sa": "https://www.amazon.sa",
  "se": "https://www.amazon.se",
  "pl": "https://www.amazon.pl",
  "ie": "https://www.amazon.ie",
  "nz": "https://www.amazon.com.au",
  "at": "https://www.amazon.at",
  "be": "https://www.amazon.com.be",
  "ch": "https://www.amazon.de",
  "pt": "https://www.amazon.es",
  "no": "https://www.amazon.de"
}

const RedirectOrNotFound = () => {
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const urlToBeChecked = window.location.pathname.substring(1);
    const fetchCountryCode = fetch('https://ipapi.co/json/')
      .then(res => res.json())
      .then(data => {
        let countryCode = data.country.toLowerCase();
        return countryCode === "gb" ? "uk" : countryCode;
      });
      
    const queryFilter = urlToBeChecked.length === 10 ? {link_id: urlToBeChecked} : {custom_path: urlToBeChecked};
    const dbQuery = supabase
      .from('links')
      .select('link_id, amazon_url')
      .match(queryFilter)
      .single();

    Promise.all([dbQuery, fetchCountryCode]).then(async ([dbResult, countryCode]) => {
      const { data, error } = dbResult;

      if (error || !data) {
        console.error(error);
        setNotFound(true);
        return;
      }

      const incrementCountryCode = countries[countryCode] ? (countryCode === 'ie' ? 'uk' : countryCode) : 'etc';
      await supabase.rpc('increment_value', { link_id: data.link_id, country_code: incrementCountryCode });

      const redirectUrl = (countries[countryCode] || countries['us']) + data.amazon_url;
      window.location.href = redirectUrl;
    }).catch(() => {
      setNotFound(true);
    });
  }, []);

  if (notFound) {
    return <NotFoundPage />;
  }

  return <div>                            
          <p className="text-xl p-5">Loading...</p>
        </div>;
};

export default RedirectOrNotFound;