// Home.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from 'react';

export default function Home() {
    const [amazonLink, setAmazonLink] = useState('');
    const [isValidLink, setIsValidLink] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsValidLink(isValidUrl(amazonLink));
    }, [amazonLink]);

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/signup', { state: { amazonLink } });
    };

    const isValidUrl = (urlString) => {
        const regex = /\b(?:https?:\/\/)?(?:www\.)?[\w-]+\.[a-z]{2,}(?:\.[a-z]{2})?\b/;
        return regex.test(urlString);
    };


    const scrollDown = (currentSectionId) => {
        const sections = ['hero', 'discover', 'authors', 'pricing'];
        const currentIndex = sections.indexOf(currentSectionId);
        const nextSectionId = sections[currentIndex + 1];

        if (nextSectionId) {
            const nextSection = document.getElementById(nextSectionId + "-divider");
            nextSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const CheckIcon = () => (
        <svg className="w-6 h-6 text-green-500 inline mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
        </svg>
    );

    const XIcon = () => (
        <svg className="w-6 h-6 text-red-500 inline mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const { theme, toggleTheme } = useTheme();

    return (
        <>
            <body className="bg-base-200">
                <div id="hero" className="hero">
                    <div className="hero-content text-center pt-32 pb-20">
                        <div className="max-w-lg">
                            <img src={process.env.PUBLIC_URL + '/getbooklogo.png'} alt="Getbook Logo" width="70px" height="70px" className="mx-auto mb-5" />
                            <h1 className="text-5xl md:text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent mb-5">Getbook</h1>
                            <p className="text-4xl md:text-6xl font-bold mb-10">
                                Every country, <span className="underline decoration-secondary decoration-4">one book link</span>.
                            </p>
                            <p className="text-xl md:text-2xl mb-5 italic">#1 Rated book linker by KDP authors.</p>
                            <div className="flex justify-center gap-4">
                                <div>
                                    <input
                                        id="link-input"
                                        type="text"
                                        placeholder="Enter an Amazon link to get started!"
                                        value={amazonLink}
                                        onChange={(e) => setAmazonLink(e.target.value)}
                                        className="input input-primary input-bordered w-4/5 sm:max-w-md md:max-w-lg"
                                    />
                                    <button
                                        className={`btn ${isValidLink ? 'btn-primary' : 'btn-disabled'} mt-5`}
                                        onClick={handleSubmit}
                                        disabled={!isValidLink}
                                    >
                                        Create Getbook.to Link
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='discover-divider' className="divider divider-accent pb-20 relative flex justify-center items-center">
                    <svg onClick={() => scrollDown('hero')} className="cursor-pointer" width="90px" height="90px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="11.5" stroke="currentColor" strokeWidth="1.5" />
                        <path d="M7 11L12 16L17 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                <div id="discover" className="text-center pb-20 px-10">
                    <h2 className="text-3xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent pb-8">A Simpler Solution</h2>
                    <p className="text-xl md:text-2xl">No more Linktrees with 5+ Amazon links! Getbook does it all <a className='italic'>with only one link</a>.</p>
                    <div className="flex justify-center gap-10 flex-wrap mt-8">
                        <div className="card w-96 bg-base-100 border border-base-content">
                            <figure className="p-10">
                                <img src={process.env.PUBLIC_URL + (theme === 'dracula' ? '/white-branch.png' : '/black-branch.png')} alt="Branch" className="w-full h-auto" />
                            </figure>
                        </div>
                    </div>
                </div>

                <div id='authors-divider' className="divider divider-accent pb-20 relative flex justify-center items-center">
                    <svg onClick={() => scrollDown('discover')} className="cursor-pointer" width="90px" height="90px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="11.5" stroke="currentColor" strokeWidth="1.5" />
                        <path d="M7 11L12 16L17 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                <div id="authors" className="text-center pb-20 px-10">
                    <h2 className="text-3xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent pb-8">The Best Authors Use Getbook</h2>
                    <p className="text-xl md:text-2xl">See which authors use Getbook to enhance their book link!</p>
                    <div className="flex justify-center mt-8">
                        <Slider {...settings} className="w-full max-w-md">
                            <div className="card w-full bg-base-100 border border-base-content">
                                <figure>
                                    <div class="flex flex-col justify-center items-center my-5">
                                        <div class="avatar">
                                            <div className='w-24 rounded-full'>
                                                <img src={process.env.PUBLIC_URL + '/juliampoetry.jpeg'} alt="juliampoetry" />
                                            </div>
                                        </div>
                                        <span class="my-3 font-bold">@juliampoetry (TikTok)</span>

                                        <div class="flex gap-10 text-sm mb-3">
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">0</span>
                                                <span>Following</span>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">93.9 K</span>
                                                <span>Followers</span>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">14.5 M</span>
                                                <span>Likes</span>
                                            </div>
                                        </div>
                                        <p class="mb-1">buy my book here ↷</p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://getbook.to/breakupblueprint" class="underline">getbook.to/breakupblueprint</a>
                                    </div>
                                </figure>
                            </div>
                            <div className="card w-full bg-base-100 border border-base-content">
                                <figure>
                                    <div class="flex flex-col justify-center items-center my-5">
                                        <div class="avatar">
                                            <div className='w-24 rounded-full'>
                                                <img src={process.env.PUBLIC_URL + '/hayleygrace.jpeg'} alt="hayleygrace" />
                                            </div>
                                        </div>
                                        <span class="my-3 font-bold">@hayleygracepoetry (TikTok)</span>

                                        <div class="flex gap-10 text-sm mb-3">
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">11</span>
                                                <span>Following</span>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">90.4 K</span>
                                                <span>Followers</span>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">7.3 M</span>
                                                <span>Likes</span>
                                            </div>
                                        </div>
                                        <p class="mb-1">“Save Me An Orange” out NOW 👇</p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://getbook.to/savemeanorange" class="underline">getbook.to/savemeanorange</a>
                                    </div>
                                </figure>
                            </div>
                            <div className="card w-full bg-base-100 border border-base-content">
                                <figure>
                                    <div class="flex flex-col justify-center items-center my-5">
                                        <div class="avatar">
                                            <div className='w-24 rounded-full'>
                                                <img src={process.env.PUBLIC_URL + '/emilybirdpoetry.jpg'} alt="emilybirdpoetry" />
                                            </div>
                                        </div>
                                        <span class="my-3 font-bold">@emilybirdpoetry (Instagram)</span>

                                        <div class="flex gap-10 text-sm mb-3">
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">565</span>
                                                <span>Posts</span>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">62.8 K</span>
                                                <span>Followers</span>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <span class="font-bold">7</span>
                                                <span>Following</span>
                                            </div>
                                        </div>
                                        <p class="mb-1">author of “This Made Me Think Of You”</p>
                                        <a target="_blank" rel="noopener noreferrer" href="https://getbook.to/thismademethinkofyou" class="underline">getbook.to/thismademethinkofyou</a>
                                    </div>
                                </figure>
                            </div>
                        </Slider>
                    </div>
                </div>

                <div id='pricing-divider' className="divider divider-accent pb-20 relative flex justify-center items-center">
                    <svg onClick={() => scrollDown('authors')} className="cursor-pointer" width="90px" height="90px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="11.5" stroke="currentColor" strokeWidth="1.5" />
                        <path d="M7 11L12 16L17 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                <div id="pricing" className="pb-10 px-4 bg-base-200">
                    <h1 className="text-3xl font-bold text-center">Pricing</h1>
                    <h2 className="text-xl text-center text-slate-500 mt-1 pb-3">
                        Totally free, begin linking your books in seconds!
                    </h2>

                    <div className="flex flex-wrap justify-center gap-8 my-8">
                        <div className="card w-80 bg-base-100  rounded-lg">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Free</h2>
                                <p className="text-slate-500">Start linking, no cost attached.</p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold">2 Getbook Links Maximum</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <XIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <XIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$0</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                <button className="btn btn-primary" onClick={() => document.getElementById("hero").scrollIntoView({ behavior: 'smooth' })}>Get Started</button>
                            </div>
                        </div>

                        <div className="card w-80 bg-base-100  rounded-lg border border-primary">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Author</h2>
                                <p className="text-slate-500">
                                    Empower your authorship! Advanced tools to get your book out there.
                                </p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold">5 Getbook Links Maximum</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <CheckIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <XIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$10</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                <button className="btn btn-primary" onClick={() => document.getElementById("hero").scrollIntoView({ behavior: 'smooth' })}>Get Started</button>
                            </div>
                        </div>

                        <div className="card w-80 bg-base-100  rounded-lg">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Author Pro</h2>
                                <p className="text-slate-500">
                                    Unlimited potential. No limitations on your book links.
                                </p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold"><a className='underline'>Unlimited</a> Getbook Links</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <CheckIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <CheckIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$30</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                <button className="btn btn-primary" onClick={() => document.getElementById("hero").scrollIntoView({ behavior: 'smooth' })}>Get Started</button>
                            </div>
                        </div>
                    </div>
                </div>

            </body>
        </>
    );
}
