import React, { useState, useEffect } from 'react';
import supabase from '../supabase';
import { Link, useLocation } from 'react-router-dom';

const SignUp = () => {
    const [link, setLink] = useState('');
    const [bookName, setBookName] = useState('');
    const [customPath, setCustomPath] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showInput, setShowInput] = useState(true);
    const [asin, setAsin] = useState('');
    const [nameError, setNameError] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [headerContent, setHeaderContent] = useState("Create your first getbook.to link!");
    const [dividerOpacity, setDividerOpacity] = useState(0);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [contentVisible, setContentVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [signUpSuccess, setSignUpSuccess] = useState(false);
    const location = useLocation();
    const [linkPassthrough, setLinkPassthrough] = useState('');

    useEffect(() => {
        if (location.state && location.state.amazonLink) {
            setLinkPassthrough(location.state.amazonLink);
            setLink(location.state.amazonLink); // Set link to linkPassthrough
        }
    }, [location]);

    useEffect(() => {
        if (location.state && location.state.amazonLink) {
            handleLinkSubmission({ preventDefault: () => { } }); // Call handleLinkSubmission after setting link
        }
    }, [link]);


    useEffect(() => {
        // Disable scroll initially
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        return () => {
            // Cleanup: Ensure scrolling is enabled when the component is unmounted
            document.body.style.overflow = 'auto';
        };
    }, []);

    const handleSignUp = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if email is valid
        if (!emailPattern.test(email)) {
            setErrorMessage("Please enter a valid email address");
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            return;
        }

        if (fullName.length < 5) {
            setErrorMessage("Full name must be at least 5 characters long");
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match");
            return;
        }

        try {
            const { user, error } = await supabase.auth.signUp({
                email,
                password,
                options: {
                    emailRedirectTo: 'https://getbook.to/login',
                    data: {
                        fullName: fullName,
                        email: email,
                        link_id: customPath,
                        amazon_url: `/dp/${asin}`,
                        name: bookName,
                        avatar_url: `https://images.amazon.com/images/P/${asin}._THUMBZZZ_.jpg`,
                    }
                }
            });

            if (error) {
                console.error('Error signing up:', error.message);
                setErrorMessage(error.message);
            } else {
                setSignUpSuccess(true);
                setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100); // Scroll to the top after sign up
                document.body.style.overflow = 'hidden'; // Disable scrolling again
            }
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    let translateYValue;

    if (window.innerWidth < 380) {
        translateYValue = '-12vh';
    } else if (window.innerWidth < 550) {
        translateYValue = '-17vh';
    } else {
        translateYValue = '-25vh';
    }

    const extractASIN = (url) => {
        const asinRegex = /\/dp\/([A-Z0-9]{10})/i;
        const matches = url.match(asinRegex);
        return matches ? matches[1] : null;
    };

    const generateUniqueId = async () => {
        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 500));
        const { data, error } = await supabase.rpc('generate_unique_id');
        setIsLoading(false);
        return data;
    };

    useEffect(() => {
        if (!showInput && link) {
            generateUniqueId().then(setCustomPath);
        }
    }, [showInput, link]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isConfirmed) {
            setTimeout(() => setContentVisible(true), 500); // Delay the appearance of the content
        }
    }, [isConfirmed]);

    const handleLinkSubmission = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        let formattedUrl = link;
        if (!/^https?:\/\//i.test(formattedUrl)) {
            formattedUrl = `https://www.${formattedUrl}`;
        }

        try {
            const url = new URL(formattedUrl);

            if (url.hostname === 'a.co') {
                setErrorMessage("If you are using the Amazon app, please copy the link from a web browser.");
                return;
            }

            const extractedAsin = extractASIN(formattedUrl);

            if (!(url.hostname.includes("amazon.") && extractedAsin)) {
                setErrorMessage("The provided link is not a valid Amazon link or does not contain an ASIN.");
                return;
            }

            setAsin(extractedAsin);
            setShowInput(false);
            generateUniqueId().then(setCustomPath);
            setHeaderContent("Confirm your link");
        } catch (error) {
            setErrorMessage("Please enter a valid URL.");
        }
    };

    const handleConfirm = () => {
        if (bookName.length < 5) {
            setNameError('The name must be at least 5 characters long.');
            setBookName('');
        } else {
            setNameError('');
            setHeaderContent("Finish Creating Link");
            setIsConfirmed(true);

            // Enable scrolling after confirming
            document.body.style.overflow = 'auto';
        }
    };

    return (
        <>
            <div className="pt-quarter-screen-mobile sm:pt-quarter-screen flex flex-col items-center justify-center min-h-screen bg-base-200 p-4 transition-all duration-700 ease-in-out" style={{ transform: `translateY(${isConfirmed ? translateYValue : '0'})`, transition: 'transform 0.5s ease-in-out' }}>
                {!signUpSuccess ? (
                    <>
                        <h1 className="text-4xl font-bold mb-16 mt-8 text-center">{headerContent}</h1>
                        {showInput ? (
                            <form onSubmit={handleLinkSubmission} className="w-4/5 max-w-md mx-auto">
                                {errorMessage && (
                                    <div role="alert" className="w-full tooltip tooltip-open tooltip-error mb-3" data-tip={errorMessage}>
                                        <input
                                            type="text"
                                            placeholder="https://amazon.com/dp/your-book"
                                            className="input input-bordered input-md w-full mb-4"
                                            value={link}
                                            onChange={(e) => setLink(e.target.value)}
                                        />
                                    </div>
                                )}
                                {!errorMessage && (
                                    <input
                                        id="link-input"
                                        type="text"
                                        placeholder="https://amazon.com/dp/your-book"
                                        className="input input-bordered input-md w-full mb-4"
                                        value={linkPassthrough}
                                        onChange={(e) => setLink(e.target.value)}
                                    />
                                )}
                                <button className="btn btn-primary w-full" type="submit">Build universal link</button>
                            </form>
                        ) : (
                            <div className='w-full flex justify-center'>
                                <div className="card card-side bg-base-100 shadow-xl mb-16 max-w-4xl w-full sm:w-3/4 lg:w-2/3 overflow-hidden">
                                    <figure className="flex-shrink-0 max-w-[160px]">
                                        <img src={`https://images.amazon.com/images/P/${asin}._THUMBZZZ_.jpg`} alt={bookName} className="max-h-[250px]" />
                                    </figure>
                                    <div className="card-body flex flex-col justify-between w-full">
                                        <>
                                            {isConfirmed ? (
                                                <div className="flex flex-col -ml-3">
                                                    <h2 className="text-2xl font-bold mb-1">{bookName}</h2>
                                                    <p className="font-semibold text-sm-special sm:text-base lg:text-lg">{`getbook.to/${customPath}`}</p>
                                                    <p className="mt-1 text-xs-special sm:text-sm-special md:text-base">{'amazon.com/dp/' + extractASIN(link)}</p>
                                                </div>
                                            ) : (
                                                <>
                                                    <input
                                                        type="text"
                                                        placeholder={nameError ? "Must be longer!" : "Enter book name"}
                                                        value={bookName}
                                                        onChange={(e) => setBookName(e.target.value)}
                                                        className={`input input-bordered ${nameError ? 'input-error' : 'input-md'} w-full sm:w-2/3 mb-1 -ml-4`}
                                                    />
                                                    <div className='-ml-3'>
                                                        <p className="font-semibold text-sm-special sm:text-base lg:text-lg">{`getbook.to/${customPath}`}</p>
                                                        <p className="mt-1 text-xs-special sm:text-sm-special md:text-base">{'amazon.com/dp/' + extractASIN(link)}</p>
                                                    </div>
                                                    <div className="card-actions justify-end mt-auto">
                                                        <button className="btn btn-primary btn-sm sm:btn-md" onClick={handleConfirm}>Confirm</button>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div
                            className='w-full'
                            style={{
                                opacity: contentVisible ? 1 : 0,
                                transition: 'opacity 0.5s ease',
                                visibility: contentVisible ? 'visible' : 'hidden',
                            }}>
                            <div id="divider" className="divider divider-accent w-full mt-6 mb-16">
                                <svg onClick={() => document.getElementById('divider').scrollIntoView({ behavior: 'smooth' })} className="cursor-pointer" width="90px" height="90px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="11.5" stroke="currentColor" strokeWidth="1.5" />
                                    <path d="M7 11L12 16L17 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>

                            <div id="signUpCard" className="card w-full max-w-md lg:max-w-lg xl:max-w-xl mx-auto shadow-2xl bg-base-100">
                                <div className="card w-full max-w-md lg:max-w-lg xl:max-w-xl mx-auto shadow-2xl bg-base-100">
                                    <form className="card-body" onSubmit={handleSignUp}>
                                        {/* Error messages */}
                                        {errorMessage && (
                                            <div role="alert" className="alert alert-error mb-3">
                                                <div className="flex items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                                    <span className="ml-3">{errorMessage}</span>
                                                </div>
                                            </div>
                                        )}

                                        {/* Full Name field */}
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Full Name</span>
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Your full name"
                                                className="input input-bordered"
                                                required
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </div>

                                        {/* Email field */}
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Email Address</span>
                                            </label>
                                            <input
                                                type="email"
                                                placeholder="Your email"
                                                className="input input-bordered"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        {/* Password field */}
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Password</span>
                                            </label>
                                            <input
                                                type="password"
                                                placeholder="Your password"
                                                className="input input-bordered"
                                                required
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>

                                        {/* Confirm password field */}
                                        <div className="form-control">
                                            <label className="label">
                                                <span className="label-text">Confirm Password</span>
                                            </label>
                                            <input
                                                type="password"
                                                placeholder="Your password again"
                                                className="input input-bordered"
                                                required
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-control mt-2">
                                            <div className='inline-flex self-center'>
                                                <input type="checkbox" className="checkbox mr-2" required /> <span className="label-text">I agree to the <Link className='underline' to='/privacy-policy' target='_blank'>Terms of Service and Privacy Policy.</Link></span>
                                            </div>
                                            <p className='self-center text-xs mt-2'>Your data is always private.</p>
                                        </div>

                                        {/* Sign up button */}
                                        <div className="form-control mt-4">
                                            <button type="submit" className="btn btn-primary">
                                                Activate Link
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center min-h-screen -mt-10">
                        <h1 className="text-4xl font-bold mb-16 mt-8 text-center text-primary">Activate & Claim</h1>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px">
                            <path d="M2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C22 17.6569 22 15.7712 22 12C22 11.0542 22.0185 10.7271 22 10M13 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2.51839 5.82475 2.22937 6.69989 2.10149 8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path> <path d="M6 8L7.66438 9.38699M15.8411 9.79908C14.0045 11.3296 13.0861 12.0949 12 12.0949C11.3507 12.0949 10.7614 11.8214 10 11.2744" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path> <circle cx="19" cy="5" r="3" stroke="currentColor" stroke-width="1.5"></circle>
                        </svg>
                        <p className="text-lg text-center mt-4">Please check <strong className='text-primary'>{email}</strong> for an email to activate your Getbook link and confirm your account if it does not already exist.</p>
                        <p className='italic text-sm text-center mt-4 w-2/3'>Check your spam/junk folder!</p>
                    </div>
                )}
            </div>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            )}
        </>
    );
};

export default SignUp;

