import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import SignUp from './pages/SignUp';
import Navbar from './components/Navbar';
import AuthNavbar from './components/AuthNavbar';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import RedirectOrNotFound from './components/RedirectOrNotFound';
import { ThemeProvider } from './contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    sessionStorage.getItem('isAuthenticated') === 'true'
  );
  const navigate = useNavigate();

  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('isAuthenticated', 'true');
    navigate('/dashboard');
  };

  const HomePage = () => (
    <>
      <Navbar />
      <Home />
      <Footer />
    </>
  );

  const LoginPage = () => (
    <>
      <Navbar />
      <Login onLogin={handleLogin} />
      <Footer />
    </>
  );

  const SignUpPage = () => (
    <>
      <div className="flex flex-col min-h-screen bg-base-200">
        <Navbar />
        <div className="flex-grow">
          <SignUp />
        </div>
      </div>
      <Footer />
    </>
  );

  const DashboardPage = () => (
    <>
      {/* <AuthNavbar /> */}
      <Dashboard />
      <Footer />
    </>
  );

  const ResetPasswordPage = () => (
    <>
      <Navbar />
      <ResetPassword />
      <Footer />
    </>
  );

  const ChangePasswordPage = () => (
    <>
      <Navbar />
      <ChangePassword />
      <Footer />
    </>
  );

  return (
    <ThemeProvider>
      <div className="app-container">
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <HomePage />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />} />
          <Route path="/signup" element={isAuthenticated ? <Navigate to="/dashboard" /> : <SignUpPage />} />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <DashboardPage /> : <Navigate to="/" />}
          />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path="*" element={<RedirectOrNotFound />} />
          <Route path="/getbookfavicon.png" element={<img src="/getbookfavicon.png" alt="GetBook Favicon" />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
