import React, { useEffect, useState } from "react";
import supabase from "../supabase";
import { useSubscription } from '../contexts/DashboardContext';

const Links = () => {
  const [linksData, setLinksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amazonUrl, setAmazonUrl] = useState("");
  const [name, setName] = useState(""); // State for the Name field
  const [error, setError] = useState(""); // State for managing error messages
  const [selectedLink, setSelectedLink] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [copiedLink, setCopiedLink] = useState('');
  const [customPath, setCustomPath] = useState("");
  const { subscription } = useSubscription();
  const [customPathUpdateError, setCustomPathUpdateError] = useState("");

  const copyToClipboard = async (link, customPath) => {
    const fullLink = `https://getbook.to/${customPath ? customPath : link}`;
    try {
      await navigator.clipboard.writeText(fullLink);
      setCopiedLink(fullLink); // Update state to show "Copied!" tooltip
      setTimeout(() => setCopiedLink(''), 2000); // Reset tooltip text after 2 seconds
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  const handleEditClick = (link) => {
    setSelectedLink(link);
    setConfirmDelete(false);
    setCustomPath(link.custom_path ? link.custom_path : link.link_id);
    hideCustomPathInputField();
    setCustomPathUpdateError("");
    document.getElementById('editModal').checked = true;
  };

  const handleDelete = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }

    setIsLoading(true);
    const { error } = await supabase
      .from('links')
      .delete()
      .eq('link_id', selectedLink.link_id);

    if (error) {
      setError('Failed to delete link');
      console.error('Error deleting link:', error);
    } else {
      document.getElementById('editModal').checked = false; // Close the modal after deletion
      await fetchData();
    }

    setIsLoading(false);
    setConfirmDelete(false); // Reset confirmDelete state
  };

  // Declare fetchData function to use in useEffect and handleAddLink
  const fetchData = async () => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    try {
      const user = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from("links")
        .select("*")
        .eq("user_id", user.data.user.id);

      if (error) {
        throw error;
      }

      setLinksData(data.map((item) => ({
        ...item,
        AmazonLink: `amazon.com${item.amazon_url}`,
        GetBookLink: item.custom_path ? `getbook.to/${item.custom_path}` : `getbook.to/${item.link_id}`,
        AvatarUrl: item.avatar_url, // Make sure this matches the database field
        Name: item.name // Make sure this matches the database field
      })));
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setError("Failed to fetch links data");
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to fetch data on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const restrictedKeywords = [
    'login',
    'signup',
    'dashboard',
    'password',
    'admin',
    'settings',
    'profile',
    'account',
    'custompath',
    'getbook',
    'links',
    'privacypolicy',
    'resetpassword',
    'changepassword',
    'termsofservice',
    'getbookpro',
    'getbookfavicon.png',
    'favicon',
    'getbookfavicon',
    'customending'
  ]; // restricted keywords

  const isRestrictedKeyword = (path) => {
    return restrictedKeywords.some(keyword => path.toLowerCase().includes(keyword));
  };
  const [showCustomPathInput, setShowCustomPathInput] = useState(false);

  // Function to show the input field for custom path
  const showCustomPathInputField = () => {
    setShowCustomPathInput(true);
  };

  // Function to hide the input field for custom path
  const hideCustomPathInputField = () => {
    setShowCustomPathInput(false);
  };

  const handleUpdateCustomLink = async () => {
    if (!subscription || subscription === "FREE") {
      setCustomPathUpdateError("Upgrade your Getbook Subscription Plan to use custom links!");
      return;
    }

    if (customPath === (selectedLink.custom_path ? selectedLink.custom_path : selectedLink.link_id)) {
      setCustomPathUpdateError("The custom URL must be changed to update it.");
      return;
    }

    if (customPath && /\s/.test(customPath)) {
      setCustomPathUpdateError("The custom URL cannot contain spaces.");
      return;
    }

    if (!/^[a-zA-Z0-9]+$/.test(customPath) && customPath != "") {
      setCustomPathUpdateError("The custom URL can only contain letters and digits.");
      return;
    }

    if (customPath && (/(http:\/\/|https:\/\/|www\.)/i.test(customPath))) {
      setCustomPathUpdateError("The custom URL must not contain other URL components like 'http://', 'https://', or 'www'.");
      return;
    }

    if (customPath.length < 5 && customPath.length != 0) {
      setCustomPathUpdateError("The custom link must at least 5 characters in length.");
      return;
    }

    if (isRestrictedKeyword(customPath)) {
      setCustomPathUpdateError("The custom link contains a restricted keyword, change it to proceed.");
      return;
    }

    try {
      setIsLoading(true);
      const { error: updateError } = await supabase
        .from('links')
        .update({ custom_path: customPath.toLowerCase() })
        .eq('link_id', selectedLink.link_id);

      if (updateError) {
        setCustomPathUpdateError('Failed to update custom link');
        console.error('Error updating custom link:', updateError);
      } else {
        document.getElementById('editModal').checked = false; // Close the modal after successful update
        await fetchData();
      }
    } catch (error) {
      console.error('Error updating custom link:', error);
      setCustomPathUpdateError('Failed to update custom link');
    } finally {
      setIsLoading(false);
    }
  };


  const handleAddLink = async () => {
    clearError();

    // FREE PLAN
    if (!subscription || subscription === "FREE") {
      if (linksData.length >= 2) {
        setError("Upgrade your Getbook Subscription Plan to add more links!");
        return;
      }
      if (customPath) {
        setError("Upgrade your Getbook Subscription Plan to use custom links!");
        return;
      }
    }
    // GETBOOK AUTHOR PLAN
    else if (subscription === process.env.REACT_APP_GETBOOK_AUTHOR) {
      if (linksData.length >= 5) {
        setError("Upgrade your Getbook Subscription Plan to add more links!");
        return;
      }
    }
    // ELSE GETBOOK AUTHOR PRO PLAN

    try {
      if (name.length < 1) {
        setError("The name must be at least 1 character long.");
        return;
      }

      if (customPath && /\s/.test(customPath)) {
        setError("The custom URL cannot contain spaces.");
        return;
      }

      if (!/^[a-zA-Z0-9]+$/.test(customPath) && customPath != "") {
        setError("The custom URL can only contain letters and digits.");
        return;
      }

      if (customPath && (/(http:\/\/|https:\/\/|www\.)/i.test(customPath))) {
        setError("The custom URL must not contain other URL components like 'http://', 'https://', or 'www'.");
        return;
      }

      if (customPath.length < 5 && customPath.length != 0) {
        throw new Error("The custom link must at least 5 characters in length.");
      }

      if (isRestrictedKeyword(customPath)) {
        setError("The custom link contains a restricted keyword, change it to proceed.");
        return;
      }

      // Validate if the input is a well-formed URL
      let formattedUrl = amazonUrl;
      if (!/^https?:\/\//i.test(formattedUrl)) {
        formattedUrl = `https://www.${formattedUrl}`;
      }

      // Validate if the input is a well-formed URL and URL is an Amazon link and contains an ASIN
      const url = new URL(formattedUrl);
      const asinRegex = /\/dp\/([A-Z0-9]{10})/i;
      const matches = amazonUrl.match(asinRegex);

      if (url.hostname === 'a.co') {
        setError("If you are using the Amazon app, please copy the link from a web browser.");
        return;
      }

      if (!(url.hostname.includes("amazon.") && matches && matches[1])) {
        setError("The provided link is not a valid Amazon link or does not contain an ASIN.");
        return;
      }

      const asin = matches[1];

      const user = await supabase.auth.getUser();
      const userId = user.data.user.id; // Store the user's ID for use in the query
      let conditions = `name.eq.${name},amazon_url.eq./dp/${asin}`;
      if (customPath) {
        conditions += `,custom_path.eq.${customPath}`;
      }
      const { data: existingLinks, error: existingError } = await supabase
        .from("links")
        .select("name, amazon_url, custom_path")
        .or(conditions)
        .eq("user_id", userId); // Add this to filter by the user's ID      


      if (existingError) {
        setError("Error checking for duplicate links.");
        return;
      }
      if (existingLinks.length > 0) {
        // console.log(existingLinks);
        setError("This name, Amazon link, or custom URL already exists under your account.");
        return;
      }
      document.getElementById("addNewLinkModal").checked = false;
      setIsLoading(true);

      // Insert the new link into the database
      const { error: insertError } = await supabase.from("links").insert([{
        name,
        user_id: (await supabase.auth.getUser()).data.user.id,
        avatar_url: `https://images.amazon.com/images/P/${asin}._THUMBZZZ_.jpg`,
        amazon_url: `/dp/${asin}`,
        custom_path: customPath.toLowerCase()
      }]);
      if (insertError) {
        alert("Failed to add new link.");
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      // Reset state and close modal after successful operation
      setAmazonUrl("");
      setName("");
      setCustomPath("");
      await fetchData();
    } catch (error) {
      // Fallback error handling if unexpected errors occur
      setError("An unexpected error occurred.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearError = () => {
    setCustomPath("");
    setError('');
  };


  return (
    <>
      <div className="pt-24 flex flex-col items-center justify-center w-full">
        <p className="text-4xl font-bold mb-8 mt-4 text-center">Manage Links</p>
        <div
          data-tip="Upgrade your Getbook Subscription Plan to add more links!"
          className={`flex justify-center mb-8 ${((!subscription || subscription === "FREE") && linksData.length >= 2) || (subscription === process.env.REACT_APP_GETBOOK_AUTHOR && linksData.length >= 5) ? "tooltip tooltip-error" : ""}`}
        >
          <label
            htmlFor="addNewLinkModal"
            onClick={clearError}
            className={`btn modal-button btn-primary ${((!subscription || subscription === "FREE") && linksData.length >= 2) || (subscription === process.env.REACT_APP_GETBOOK_AUTHOR && linksData.length >= 5) ? "btn-disabled" : ""}`}
          >
            CREATE GETBOOK.TO LINK
          </label>
        </div>


        <div className="w-full flex flex-col items-center justify-center p-4">
          {linksData.map((item, index) => (
            <div key={index} className="card card-side bg-base-100 shadow-xl mb-8 max-w-4xl w-full sm:w-3/4 lg:w-2/3 overflow-hidden">
              <figure className="flex-shrink-0 max-h-[250px] max-w-[157px] sm:h-auto sm:w-auto">
                <img src={item.AvatarUrl} alt={item.Name} className="w-full object-cover sm:object-contain" />
              </figure>
              <div className="card-body flex flex-col justify-between w-full -ml-4">
                <h2 className="card-title font-bold">{item.Name}</h2>
                <div>
                  <div
                    className="tooltip tooltip-accent"
                    {...(copiedLink === `https://getbook.to/${item.custom_path ? item.custom_path : item.link_id}` && { 'data-tip': 'Copied!' })}
                  >
                    <button className="hover:underline"
                      onClick={() => copyToClipboard(item.link_id, item.custom_path)}
                    >
                      {<a className="text-sm-special sm:text-base lg:text-lg">{item.GetBookLink}</a>}
                      <svg className="inline-block h-6 w-6 ml-1 pb-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M8 5.00005C7.01165 5.00082 6.49359 5.01338 6.09202 5.21799C5.71569 5.40973 5.40973 5.71569 5.21799 6.09202C5 6.51984 5 7.07989 5 8.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.07989 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V8.2C19 7.07989 19 6.51984 18.782 6.09202C18.5903 5.71569 18.2843 5.40973 17.908 5.21799C17.5064 5.01338 16.9884 5.00082 16 5.00005M8 5.00005V7H16V5.00005M8 5.00005V4.70711C8 4.25435 8.17986 3.82014 8.5 3.5C8.82014 3.17986 9.25435 3 9.70711 3H14.2929C14.7456 3 15.1799 3.17986 15.5 3.5C15.8201 3.82014 16 4.25435 16 4.70711V5.00005"></path>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <p className="mt-1 text-sm-special sm:text-base lg:text-lg">{item.AmazonLink}</p>
                </div>
                <div className="card-actions justify-end mt-auto">
                  <button className="btn btn-sm sm:btn-md" onClick={() => handleEditClick(item)}>Edit</button>
                </div>
              </div>
            </div>
          ))}
        </div>


        {/* MODALS */}
        <input type="checkbox" id="addNewLinkModal" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="modal-box">
            {error && (
              <div role="alert" className="alert alert-error mb-3">
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  <span className="ml-3">{error}</span>
                </div>
              </div>
            )}
            <h3 className="text-lg font-bold">Provide a valid Amazon URL and we'll do the rest!</h3>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Book</span>
              </label>
              <input type="text" placeholder="Enter book name" className="input input-bordered w-full" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Amazon URL</span>
              </label>
              <label className="input-group">
                <span>🔗</span>
                <input type="text" placeholder="https://amazon.com/dp/your-book" className="input input-bordered w-full" value={amazonUrl} onChange={(e) => setAmazonUrl(e.target.value)} />
              </label>
            </div>
            <div className="form-control mt-4 relative">
              <label className="label">
                <span className="label-text">Custom URL (ex: getbook.to/<a className="font-bold">customending</a>)</span>
              </label>
              <div className={`relative ${(!subscription || subscription === "FREE") ? "tooltip tooltip-error" : ""}`} data-tip="Upgrade your Getbook Subscription Plan to use custom links!">
                <label className="input-group">
                  <span>getbook.to/</span>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    disabled={(!subscription || subscription === "FREE")}
                    placeholder="Optional, leave blank for random link."
                    value={customPath}
                    onChange={(e) => setCustomPath(e.target.value)}
                  />
                </label>
              </div>

            </div>
            <div className="modal-action">
              <button className="btn btn-primary" onClick={handleAddLink} disabled={isLoading}>
                Add
              </button>
              <label htmlFor="addNewLinkModal" className="btn">Close</label>
            </div>
          </div>
        </div>

        <input type="checkbox" id="editModal" className="modal-toggle" />
        <div className="modal" id="editModalContainer">
          <div className="modal-box">
            <h3 className="font-bold text-lg text-center mb-4">Edit Link</h3>
            <div className="flex flex-col items-center gap-4">
              {showCustomPathInput ? (
                <div
                  className={`flex flex-col items-center ${customPathUpdateError ? "tooltip tooltip-error" : ""}`}
                  data-tip={customPathUpdateError}
                >
                  <label className="input-group">
                    <span>getbook.to/</span>
                    <input
                      type="text"
                      className="input input-bordered input-primary flex-grow"
                      disabled={(!subscription || subscription === "FREE")}
                      placeholder="Optional, leave blank for random link."
                      value={customPath}
                      onChange={(e) => setCustomPath(e.target.value)}
                    />
                  </label>
                  <button onClick={handleUpdateCustomLink} className="btn btn-primary btn-outline mt-4">Update</button>
                </div>
              ) : (
                <>
                  <button className="btn btn-outline btn-error" onClick={handleDelete}>
                    {confirmDelete ? 'Are you sure?' : 'Delete'}
                  </button>
                  <div
                    className={`relative ${(!subscription || subscription === "FREE") ? "tooltip tooltip-error" : ""}`}
                    data-tip="Upgrade your Getbook Subscription Plan to use custom links!"
                  >
                    <button
                      className={`${(!subscription || subscription === "FREE") ? "btn btn-outline btn-disabled" : "btn btn-outline btn-primary"}`}
                      onClick={showCustomPathInputField}
                    >
                      Manage Custom Path
                    </button>
                  </div>
                </>
              )}
              {/* Close button using label to toggle the checkbox */}
              <label htmlFor="editModal" className="btn">Cancel</label>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
    </>
  );
};

export default Links;