import React, { useState } from "react";
import Links from "../components/Links";
import Statistics from "../components/Statistics";
import Account from "../components/Account";
import AuthNavbar from "../components/AuthNavbar";
import Subscription from "../components/Subscriptions";
import { useNavigate } from 'react-router-dom';
import supabase from "../supabase";
import { SubscriptionProvider } from "../contexts/DashboardContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated") === "true";
  const [activeComponent, setActiveComponent] = useState("Links");

  const handleNavChange = (component) => {
    setActiveComponent(component);
    window.scrollTo(0, 0);
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw error;
      }
      sessionStorage.removeItem('isAuthenticated');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <SubscriptionProvider>
            <AuthNavbar onNavChange={handleNavChange} onSignOut={handleSignOut} />
            <div className="min-h-screen flex flex-col justify-start items-center bg-base-200 pb-10">
              {activeComponent === "Links" && <Links />}
              {activeComponent === "Statistics" && <Statistics />}
              {activeComponent === "Account" && <Account />}
              {activeComponent === "Subscription" && <Subscription />}
            </div>
          </SubscriptionProvider>
        </>
      )}
    </>
  );
};

export default Dashboard;
