import React, { useState, useEffect } from 'react';
import { useSubscription } from '../contexts/DashboardContext';
import supabase from '../supabase';
import { FunctionsHttpError } from '@supabase/supabase-js';

const CheckIcon = () => (
    <svg className="w-6 h-6 text-green-500 inline mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
    </svg>
);

const XIcon = () => (
    <svg className="w-6 h-6 text-red-500 inline mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
);

function Subscription() {
    const { subscription } = useSubscription();
    const [isLoading, setIsLoading] = useState(true);

    const fetchStripeCheckout = async (price) => {
        setIsLoading(true);
        // console.log(price);
        const { data, error } = await supabase.functions.invoke('stripe-checkout', {
            body: JSON.stringify({ price })
        });
        // console.log(data);
        if (data.url) {
            window.location.href = data.url;
        } else {
            alert('An error occurred while processing your request. Please try again later.');
        }
        if (error && error instanceof FunctionsHttpError) {
            const errorMessage = await error.context.json()
            console.log('Function returned an error', errorMessage)
        }
    }

    const fetchStripePortal = async () => {
        setIsLoading(true);
        const { data, error } = await supabase.functions.invoke('stripe-portal', {
            body: JSON.stringify({})
        });
        if (data.url) {
            window.location.href = data.url;
        } else {
            alert('An error occurred while processing your request. Please try again later.');
        }
        if (error && error instanceof FunctionsHttpError) {
            const errorMessage = await error.context.json()
            console.log('Function returned an error', errorMessage)
        }
    }

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <div className='container mx-auto mt-28'>
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            )}
            {!isLoading && (subscription === null || subscription === "FREE") && (
                <div id="pricing" className="pb-10 px-4 bg-base-200">
                    <h1 className="text-3xl font-bold text-center">Subscription Plans</h1>
                    <h2 className="text-xl text-center text-slate-500 mt-1 pb-3">
                        Affordable plans to boost your book's reach and success.
                    </h2>

                    <div className="flex flex-wrap justify-center gap-8 my-8">
                        <div className="card w-80 bg-base-100 rounded-lg shadow-xl">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Free</h2>
                                <p className="text-slate-500">Start linking, no cost attached.</p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold">2 Getbook Links Maximum</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <XIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <XIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$0</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                {subscription === null || subscription === "FREE" ? (
                                    <button className="btn btn-primary" disabled>Current Plan</button>
                                ) : (
                                    <button className="btn btn-primary">Upgrade Now</button>
                                )}
                            </div>
                        </div>

                        <div className="card w-80 bg-base-100 rounded-lg shadow-xl border border-primary">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Author</h2>
                                <p className="text-slate-500">
                                    Empower your authorship! Advanced tools to get your book out there.
                                </p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold">5 Getbook Links Maximum</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <CheckIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <XIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$10</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                <button className="btn btn-primary" onClick={() => fetchStripeCheckout(process.env.REACT_APP_GETBOOK_AUTHOR)}>Upgrade Now</button>
                            </div>
                        </div>

                        <div className="card w-80 bg-base-100 rounded-lg shadow-xl">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Author Pro</h2>
                                <p className="text-slate-500">
                                    Unlimited potential. No limitations on your book links.
                                </p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold"><a className='underline'>Unlimited</a> Getbook Links</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <CheckIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <CheckIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$30</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                <button className="btn btn-primary" onClick={() => fetchStripeCheckout(process.env.REACT_APP_GETBOOK_AUTHOR_PRO)}>Upgrade Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && (subscription === process.env.REACT_APP_GETBOOK_AUTHOR || subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO) && (
                <div id="pricing" className="pb-10 px-4 bg-base-200">
                    <h1 className="text-3xl font-bold text-center">Subscription Plans</h1>
                    <h2 className="text-xl text-center text-slate-500 mt-1 pb-3">
                        Affordable plans to boost your book's reach and success.
                    </h2>

                    <div className="flex flex-wrap justify-center gap-8 my-8">
                        <div className="card w-80 bg-base-100 rounded-lg shadow-xl">
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Free</h2>
                                <p className="text-slate-500">Start linking, no cost attached.</p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold">2 Getbook Links Maximum</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <XIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <XIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$0</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                <button className="btn btn-primary btn-disabled"> → </button>
                            </div>
                        </div>
                        <div className={`card w-80 bg-base-100 rounded-lg shadow-xl ${(subscription === process.env.REACT_APP_GETBOOK_AUTHOR) ? "border border-primary" : ""}`}>
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Author</h2>
                                <p className="text-slate-500">
                                    Empower your authorship! Advanced tools to get your book out there.
                                </p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold">5 Getbook Links Maximum</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <CheckIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <XIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$10</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                {subscription === process.env.REACT_APP_GETBOOK_AUTHOR ? (
                                    <button className="btn btn-primary" onClick={() => fetchStripePortal()}>Manage / Cancel</button>
                                ) : (
                                    <button className="btn btn-primary" disabled> → </button>
                                )}
                            </div>
                        </div>

                        <div className={`card w-80 bg-base-100 rounded-lg shadow-xl ${(subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO) ? "border border-primary" : ""}`}>
                            <div className="card-body text-center">
                                <h2 className="card-title text-primary">Getbook Author Pro</h2>
                                <p className="text-slate-500">
                                    Unlimited potential. No limitations on your book links.
                                </p>
                                <div className="mt-4">
                                    <ul className="text-left">
                                        <li className="flex items-center">
                                            <CheckIcon /><span className="ml-2 font-bold"><a className='underline'>Unlimited</a> Getbook Links</span>
                                        </li>
                                        <li className="flex items-center mt-2">
                                            <CheckIcon /><span className="ml-2">Custom URL Ending</span>
                                        </li>
                                        <li className="flex items-center mt-2 tooltip tooltip-bottom tooltip-open tooltip-primary" data-tip="Coming soon!">
                                            <CheckIcon /><span className="ml-2">Charts & <a className='line-through'>AI Insights</a></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-10 mb-6">
                                    <span className="text-4xl font-bold">$30</span>
                                    <span className="text-slate-500"> per month</span>
                                </div>
                                {subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO ? (
                                    <button className="btn btn-primary" onClick={() => fetchStripePortal()}>Manage / Cancel</button>
                                ) : (
                                    <button className="btn btn-primary" disabled> ← </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Subscription;
