import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <embed src="/Getbook Privacy Policy.pdf" type="application/pdf" width="100%" height="600px" />
        </div>
    );
};

export default PrivacyPolicy;