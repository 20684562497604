import React, { useEffect, useState } from "react";
import supabase from "../supabase";
import { Link, useNavigate } from "react-router-dom";
import { useSubscription } from '../contexts/DashboardContext';

const Account = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { subscription } = useSubscription();
  const [confirmDeleteAccount, setconfirmDeleteAccount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      window.scrollTo(0, 0);
      try {
        const user = await supabase.auth.getUser();
        if (user) {
          setUserData(user);
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleDeleteAccountButtonClick = async () => {
    if (subscription === process.env.REACT_APP_GETBOOK_AUTHOR || subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO) {
      return;
    }

    if (!confirmDeleteAccount) {
      setconfirmDeleteAccount(true);
      return;
    }

    try {
      const { data, error } = await supabase.rpc('add_to_delete');
      if (error) {
        console.error('Error deleting account:', error);
        return;
      }

      await handleSignOut();
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      sessionStorage.removeItem('isAuthenticated');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <div className="container mx-auto mt-28">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}

      {!isLoading && userData && (
        <div className="flex flex-col items-center px-4">
          <p className="mb-5 text-4xl md:text-4xl font-bold flex justify-center">
            Account Settings
          </p>
          <div className="avatar placeholder">
            <div className="bg-orange-700 text-neutral-content rounded-full w-24">
              <span className="text-3xl">
                {userData.data.user.email[0].toUpperCase()}
              </span>
            </div>
          </div>

          <div className="card p-6 pb-7 mt-8 max-w-xl w-full bg-base-100 shadow-xl">
            <div className="flex justify-between items-start">
              <div className="text-xl font-bold">Profile</div>
              <div className="text-lg flex flex-col text-right mb-3 overflow-hidden">
                <span className="truncate">{userData.data.user.email}</span>
                <span className="text-sm text-gray-500">Email Address</span>
              </div>
            </div>
            <div className="w-full text-right">
              <div data-tip="You can manage this in the Subscription tab." className="tooltip tooltip-info tooltip-bottom text-right">
                <div className="text-lg text-white-200">
                  {subscription === process.env.REACT_APP_GETBOOK_AUTHOR && "Getbook Author"}
                  {subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO && "Getbook Author Pro"}
                  {subscription !== process.env.REACT_APP_GETBOOK_AUTHOR && subscription !== process.env.REACT_APP_GETBOOK_AUTHOR_PRO && "Getbook Free"}
                </div>
                <span className="text-sm text-gray-500">Subscription Plan</span>
              </div>
            </div>
          </div>
          <div className="card p-6 pb-7 mt-8 max-w-xl w-full bg-base-100 shadow-xl">
            <div className="flex justify-between items-start">
              <div className="text-xl font-bold">Password</div>
              <Link to="/reset-password" className="btn btn-outline btn-primary">Change Password</Link>
            </div>
          </div>

          {/* Delete Account Card */}
          <div className="card p-6 pb-7 mt-8 max-w-xl w-full bg-base-100 shadow-xl">
            <div className="flex justify-between items-start">
              <div className="text-xl font-bold">Danger Zone</div>
              <div className="flex flex-col items-end text-right">
                <button
                  className={`btn btn-error ${subscription === process.env.REACT_APP_GETBOOK_AUTHOR || subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO ? "btn-disabled" : "btn-outline"}`}
                  onClick={handleDeleteAccountButtonClick}>
                  {confirmDeleteAccount ? 'Are you sure?' : 'Delete account'}
                </button>
                {(subscription === process.env.REACT_APP_GETBOOK_AUTHOR || subscription === process.env.REACT_APP_GETBOOK_AUTHOR_PRO) && (
                  <span className="text-xs text-error max-w-xxs break-words mt-3">To delete your account, you need to cancel your Getbook subscription (in the Subscription tab).</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
