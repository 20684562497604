import React, { useState, useEffect } from 'react';
import supabase from '../supabase';

export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);

    const handleReset = async (e) => {
        e.preventDefault();
        setIsDisabled(true);  // Disable the button
        setCountdown(60);  // Reset the countdown
        const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: 'https://getbook.to/change-password'
        });
        if (error) {
            setMessage('Failed to send reset email: ' + error.message);
            setIsDisabled(false);
        } else {
            setMessage('If this user exists, we have sent you a password reset email. Make sure to check spam and junk folders.');
            let interval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(interval);
                        setIsDisabled(false);  // Re-enable the button
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
    };

    useEffect(() => {
        return () => clearInterval();
    }, []);

    return (
        <div className="hero min-h-screen bg-base-200">
            <div className="hero-content flex flex-col">
                <div className="text-center">
                    <h1 className="text-5xl font-bold">Reset Password</h1>
                </div>
                <div className="card w-full max-w-md shadow-2xl bg-base-100">
                    <form className="card-body" onSubmit={handleReset}>
                        {message && (
                            <div className="alert alert-success flex items-center space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                <span>{message}</span>
                            </div>
                        )}
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Email Address</span>
                            </label>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="input input-bordered"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={isDisabled}
                            />
                        </div>
                        <div className="form-control mt-6">
                            <button type="submit" className="btn btn-primary flex items-center justify-center" disabled={isDisabled}>
                                {isDisabled ? (
                                    <>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
</svg>
                                         <a className='ml-2'>Resend in {countdown} s</a>
                                    </>
                                ) : 'Send Reset Email'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
